
import './App.css';

function App() {
  return (
    <iframe src='https://ericpolley.com/#/blog' title="Blog" className="App">

    </iframe>
  );
}

export default App;
